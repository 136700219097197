<template>
  <div class="row align-items-end m-b-10">
    <div class="col-lg-2">
      <Input
        v-model="article.reportageId"
        @blur="$v.article.reportageId.$touch()"
        :error="$v.article.reportageId.$error"
        id="article_reportage"
        disabled="disabled"
        :label="$t('onlineReportageVlm.articleSelectReportage.reportageId')"
        disable-form-group
      />
    </div>
    <div class="col-lg-4">
      <Input
        :value="article.expanded.reportage ? article.expanded.reportage.title : null"
        id="article_reportage"
        disabled="disabled"
        :label="$t('onlineReportageVlm.articleSelectReportage.reportageTitle')"
        disable-form-group
      />
    </div>
    <div class="col-lg-6">
      <button
        v-if="!articleEditDisabled"
        class="btn btn-info"
        @click="showReportageModal"
      >
        <i class="mdi mdi-arrange-send-backward" /> {{ $t('onlineReportageVlm.articleSelectReportage.selectReportageButton') }}
      </button>
    </div>
    <ArticleOnlineReportageModalVlm
      v-if="reportageModal"
      @set-data="confirmSelectedReportage"
      @close="closeReportageModal"
    />
    <AbstractModal
      v-if="confirmModal"
      :title="$t('onlineReportageVlm.articleSelectReportage.confirmModal.title')"
      buttons-text="yes-no"
      @confirm="setData({ rewriteArticle: true })"
      @close="setData({ rewriteArticle: false })"
    >
      <template #modalContent>
        <div v-html="$t('onlineReportageVlm.articleSelectReportage.confirmModal.body1', { title: selectedReportage.selectedData.title }) " />
        <div v-html="$t('onlineReportageVlm.articleSelectReportage.confirmModal.body2')" />
      </template>
    </AbstractModal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { requiredIf } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import ArticleOnlineReportageModalVlm from '@/components/article/ArticleOnlineReportageModalVlm'
import AbstractModal from '@/components/nmhEditor/modals/AbstractModal'
import { ARTICLE_TYPE_ONLINE_REPORTAGE } from '@/components/mixins/valueObject/ArticleTypeMixin'

export default {
  name: 'ArticleOnlineReportageSelectVlm',
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      reportageModal: false,
      confirmModal: false,
      selectedReportage: null
    }
  },
  components: {
    AbstractModal,
    ArticleOnlineReportageModalVlm,
    Input
  },
  validations () {
    return {
      article: {
        reportageId: {
          required: requiredIf(article => {
            return article.type === ARTICLE_TYPE_ONLINE_REPORTAGE
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters('article', ['articleEditDisabled'])
  },
  methods: {
    showReportageModal () {
      this.reportageModal = true
    },
    closeReportageModal () {
      this.reportageModal = false
    },
    confirmSelectedReportage (selectedReportage) {
      this.selectedReportage = selectedReportage
      this.confirmModal = true
    },
    setData ({ rewriteArticle }) {
      this.confirmModal = false
      const selectedReportage = this.selectedReportage.selectedData
      Vue.set(this.article, 'reportageId', selectedReportage.id)
      this.article.flag.reportage = true
      this.article.expanded.reportage = selectedReportage
      if (rewriteArticle) {
        this.article.field.title = selectedReportage.title
        this.article.field.shortTitle = selectedReportage.title
        this.article.field.firstParagraph = selectedReportage.description
      }
    }
  }
}
</script>
