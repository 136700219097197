export default {
  blocks: [
    {
      type: 'carTableBlock',
      validation (properties) {
        return Array.isArray(properties.testIds) &&
          properties.testIds.length > 0
      }
    },
    {
      type: 'codeBlock',
      validation (properties) {
        return typeof properties.code === 'string' &&
          properties.code.length > 0
      },
      transform (properties) {
        properties.code = properties.code?.replace(/\s+/g, ' ') ?? properties.code
      }
    },
    {
      type: 'tableBlock',
      validation (properties) {
        return typeof properties.tableContent.html === 'string' &&
          properties.tableContent.html.length > 0
      },
      transform (properties) {
        properties.tableContent.html = properties.tableContent.html?.replace(/\s+/g, ' ') ?? properties.tableContent.html
      }
    },
    {
      type: 'thirdPartyEmbedBlock',
      validation (properties) {
        return typeof properties.type === 'string' &&
          properties.type.length > 0 &&
          typeof properties.originalContent === 'string' &&
          properties.originalContent.length > 0
      }
    },
    {
      type: 'headingBlock',
      validation (properties) {
        return typeof properties.text === 'string' &&
          properties.text.length > 0 &&
          typeof properties.headingType === 'string' &&
          properties.headingType.length > 0
      },
      transform (properties) {
        properties.text = properties.text?.replace(/\s+/g, ' ') ?? properties.text
      }
    },
    {
      type: 'imageBlock',
      validation (properties) {
        return !isNaN(properties.damMediaId) &&
          properties.damMediaId > 0
      }
    },
    {
      type: 'infoBoxBlock',
      validation (properties) {
        return !isNaN(properties.infoBoxId) &&
          typeof properties.infoBoxId !== 'string'
      }
    },
    {
      type: 'infographicBlock',
      validation (properties) {
        return typeof properties.infographicId === 'string' &&
          properties.infographicId.length > 0
      }
    },
    {
      type: 'linkToGalleryBlock',
      validation (properties) {
        return !isNaN(properties.page) &&
          properties.page > 0 &&
          typeof properties.page !== 'string' &&
          typeof properties.text === 'string' &&
          properties.text.length > 0
      }
    },
    {
      type: 'onlineSportMatchBlock',
      validation (properties) {
        return typeof properties.matchId === 'string' &&
          properties.matchId.length > 0 &&
          typeof properties.matchType === 'string' &&
          properties.matchType.length > 0
      }
    },
    {
      type: 'orderedListBlock',
      validation (properties) {
        return Array.isArray(properties.items) &&
          properties.items.length > 0
      },
      transform (properties) {
        properties.items = properties.items.map(item => item?.replace(/\s+/g, ' ') ?? item)
      }
    },
    {
      type: 'sourceBlock',
      validation (properties) {
        return Array.isArray(properties.items) &&
          properties.items.length > 0
      }
    },
    {
      type: 'pageBreakBlock',
      validation (properties) {
        return properties.pageBreak === true
      }
    },
    {
      type: 'paidContentBreakBlock',
      validation (properties) {
        return properties.paidContentBreak === true
      }
    },
    {
      type: 'paragraphBlock',
      validation (properties) {
        return typeof properties.text === 'string' &&
          properties.text.length > 0
      },
      transform (properties) {
        properties.text = properties.text?.replace(/\s+/g, ' ') ?? properties.text
      }
    },
    {
      type: 'pollBlock',
      validation (properties) {
        return !isNaN(properties.pollId) &&
          typeof properties.pollId !== 'string'
      }
    },
    {
      type: 'quizBlock',
      validation (properties) {
        return !isNaN(properties.quizId) &&
          typeof properties.quizId !== 'string'
      }
    },
    {
      type: 'reportageBlock',
      validation (properties) {
        return !isNaN(properties.reportageArticleId) &&
          typeof properties.reportageArticleId !== 'string' &&
          typeof properties.reportageArticleSettingStatus === 'string'
      }
    },
    {
      type: 'quoteBlock',
      validation (properties) {
        return typeof properties.author === 'string' &&
          properties.author.length > 0 &&
          typeof properties.text === 'string' &&
          properties.text.length > 0
      },
      transform (properties) {
        properties.author = properties.author?.replace(/\s+/g, ' ') ?? properties.author
        properties.text = properties.text?.replace(/\s+/g, ' ') ?? properties.text
      }
    },
    {
      type: 'sportTableBlock',
      validation (properties) {
        return typeof properties.seasonId === 'string' &&
          properties.seasonId.length > 0 &&
          !isNaN(properties.activeTab) &&
          properties.activeTab > 0
      }
    },
    {
      type: 'relatedArticleBlock',
      validation (properties) {
        return typeof properties.articleId === 'number'
      }
    },
    {
      type: 'unorderedListBlock',
      validation (properties) {
        return Array.isArray(properties.items) &&
          properties.items.length > 0
      },
      transform (properties) {
        properties.items = properties.items.map(item => item?.replace(/\s+/g, ' ') ?? item)
      }
    },
    {
      type: 'videoBlock',
      validation (properties) {
        return typeof properties.videoId === 'number' && properties.videoId
      }
    },
    {
      type: 'youtubeBlock',
      validation (properties) {
        return typeof properties.youtubeLink === 'string' &&
          properties.youtubeLink.length > 0
      }
    },
    {
      type: 'themeBlock',
      validation (properties) {
        return typeof properties.articlesCount === 'number' &&
          typeof properties.themeId === 'number' &&
          typeof properties.display === 'string' &&
          properties.display.length > 0
      }
    },
    {
      type: 'seriesBlock',
      validation (properties) {
        return typeof properties.seriesId === 'number' &&
          typeof properties.articlesCount === 'number' &&
          typeof properties.display === 'string' &&
          properties.display.length > 0
      }
    },
    {
      type: 'specialArticleGroupBlock',
      validation (properties) {
        return typeof properties.specialArticleGroupId === 'number' &&
          typeof properties.articlesCount === 'number' &&
          typeof properties.display === 'string' &&
          properties.display.length > 0
      }
    }
  ]
}
