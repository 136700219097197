<template>
  <div
    class="block-container"
    :data-type="blockType"
    :data-index="index"
    :data-position-x="position.x"
    :data-position-y="position.y"
    :id="`block-container-${position.y}`"
    @mousedown="mousedownHandler"
  >
    <component
      :is="componentName"
      :block-type="blockType"
      :index="index"
      :properties="this.properties"
    />
    <BtnAddBlock :index="index" :separator="true" />
  </div>
</template>

<script>
import BtnAddBlock from '@/components/nmhEditor/btns/BtnAddBlock'
import CarTableBlock from '@/components/nmhEditor/blocks/embedBlocks/CarTableBlock'
import CodeBlock from '@/components/nmhEditor/blocks/internalBlocks/CodeBlock'
import HeadingBlock from '@/components/nmhEditor/blocks/internalBlocks/HeadingBlock'
import ImageBlock from '@/components/nmhEditor/blocks/embedBlocks/ImageBlock'
import InfoBoxBlock from '@/components/nmhEditor/blocks/embedBlocks/InfoBoxBlock'
import InfographicBlock from '@/components/nmhEditor/blocks/embedBlocks/InfographicBlock.vue'
import LinkToGalleryBlock from '@/components/nmhEditor/blocks/embedBlocks/LinkToGalleryBlock'
import ReportageBlock from '@/components/nmhEditor/blocks/embedBlocks/ReportageBlock'
import OnlineSportMatchBlock from '@/components/nmhEditor/blocks/embedBlocks/OnlineSportMatchBlock'
import OrderedOrBulletListBlock from '@/components/nmhEditor/blocks/internalBlocks/OrderedOrBulletListBlock'
import PageBreakBlock from '@/components/nmhEditor/blocks/embedBlocks/PageBreakBlock'
import PaidContentBreakBlock from '@/components/nmhEditor/blocks/embedBlocks/PaidContentBreakBlock'
import ParagraphBlock from '@/components/nmhEditor/blocks/internalBlocks/ParagraphBlock'
import SourceBlock from '@/components/nmhEditor/blocks/internalBlocks/SourceBlock'
import PollBlock from '@/components/nmhEditor/blocks/embedBlocks/PollBlock'
import QuizBlock from '@/components/nmhEditor/blocks/embedBlocks/QuizBlock'
import QuoteBlock from '@/components/nmhEditor/blocks/internalBlocks/QuoteBlock'
import RelatedArticleBlock from '@/components/nmhEditor/blocks/embedBlocks/RelatedArticleBlock'
import SpecialArticleGroupBlock from '@/components/nmhEditor/blocks/embedBlocks/SpecialArticleGroupBlock.vue'
import ThemeBlock from '@/components/nmhEditor/blocks/embedBlocks/ThemeBlock.vue'
import SeriesBlock from '@/components/nmhEditor/blocks/embedBlocks/SeriesBlock.vue'
import SportTableBlock from '@/components/nmhEditor/blocks/embedBlocks/SportTableBlock'
import TableBlock from '@/components/nmhEditor/blocks/embedBlocks/TableBlock'
import ThirdPartyEmbedBlock from '@/components/nmhEditor/blocks/embedBlocks/ThirdPartyEmbedBlock'
import VideoBlock from '@/components/nmhEditor/blocks/embedBlocks/VideoBlock'
import YoutubeBlock from '@/components/nmhEditor/blocks/embedBlocks/YoutubeBlock'
export default {
  name: 'BlockContainer',
  components: {
    BtnAddBlock,
    CarTableBlock,
    CodeBlock,
    HeadingBlock,
    ImageBlock,
    InfoBoxBlock,
    InfographicBlock,
    LinkToGalleryBlock,
    ReportageBlock,
    OnlineSportMatchBlock,
    OrderedOrBulletListBlock,
    PageBreakBlock,
    SourceBlock,
    PaidContentBreakBlock,
    ParagraphBlock,
    PollBlock,
    QuizBlock,
    QuoteBlock,
    RelatedArticleBlock,
    ThemeBlock,
    SeriesBlock,
    SpecialArticleGroupBlock,
    SportTableBlock,
    TableBlock,
    ThirdPartyEmbedBlock,
    VideoBlock,
    YoutubeBlock
  },
  props: {
    blockType: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    properties: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    }
  },
  computed: {
    componentName () {
      if (['orderedListBlock', 'unorderedListBlock'].includes(this.blockType)) {
        return 'OrderedOrBulletListBlock'
      }
      return this.blockType
    }
  },
  methods: {
    mousedownHandler () {
      this.$store.commit('nmhEditorStore/SET_LAST_CLICKED_BLOCK', {
        index: this.index,
        positionX: this.position.x,
        positionY: this.position.y,
        type: this.blockType
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .block-container {
    outline: none;
    @include bp(0 11) {
      @include margin(_ _ 25px);
    }
    &--last {
      @include bp(11) {
        @include margin(_ _ 40px);
      }
    }
    &--ghost {
      opacity: 0;
    }
    > [data-type="block"] {
      @include relative(_);
      @include radius(6px);
      @include grid-gap(5px);
      display: grid;
      @include bp(11) {
        @include grid-gap(10px);
        grid-template-columns: auto min-content;
      }
    }
  }
</style>
