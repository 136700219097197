import { mapGetters } from 'vuex'

export const ARTICLE_TYPE_ARTICLE = 'article'
export const ARTICLE_TYPE_EXTERNAL = 'external'
export const ARTICLE_TYPE_VIDEO = 'video'
export const ARTICLE_TYPE_QUIZ = 'quiz'
export const ARTICLE_TYPE_PHOTOSTORY = 'photostory'
export const ARTICLE_TYPE_TEST = 'test'
export const ARTICLE_TYPE_LONGFORM = 'longform'
export const ARTICLE_TYPE_RECIPE = 'recipe'
export const ARTICLE_TYPE_DISEASE = 'disease'
export const ARTICLE_TYPE_ENCYCLOPEDY = 'encyclopedy'
export const ARTICLE_TYPE_ONLINE_REPORTAGE = 'reportage'

export const ARTICLE_TYPE_DEFAULT = ARTICLE_TYPE_ARTICLE

export default {
  data () {
    return {
      ARTICLE_TYPE_ARTICLE,
      ARTICLE_TYPE_EXTERNAL,
      ARTICLE_TYPE_VIDEO,
      ARTICLE_TYPE_QUIZ,
      ARTICLE_TYPE_PHOTOSTORY,
      ARTICLE_TYPE_TEST,
      ARTICLE_TYPE_LONGFORM,
      ARTICLE_TYPE_RECIPE,
      ARTICLE_TYPE_DISEASE,
      ARTICLE_TYPE_ENCYCLOPEDY,
      ARTICLE_TYPE_ONLINE_REPORTAGE,
      vlmExcludedArticleTypes: [ARTICLE_TYPE_EXTERNAL, ARTICLE_TYPE_VIDEO,
        ARTICLE_TYPE_RECIPE, ARTICLE_TYPE_PHOTOSTORY, ARTICLE_TYPE_QUIZ],
      nmhExcludedArticleTypes: [ARTICLE_TYPE_ONLINE_REPORTAGE, ARTICLE_TYPE_RECIPE],
      reducedContentArticleTypes: [ARTICLE_TYPE_EXTERNAL, ARTICLE_TYPE_QUIZ, ARTICLE_TYPE_RECIPE, ARTICLE_TYPE_ONLINE_REPORTAGE]
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    excludedArticleTypes () {
      return this.vlm ? this.vlmExcludedArticleTypes : this.nmhExcludedArticleTypes
    },
    articleTypeValues () {
      return [
        {
          id: ARTICLE_TYPE_ARTICLE,
          title: this.$t('article.article_type.article')
        },
        {
          id: ARTICLE_TYPE_EXTERNAL,
          title: this.$t('article.article_type.external')
        },
        {
          id: ARTICLE_TYPE_VIDEO,
          title: this.$t('article.article_type.video')
        },
        {
          id: ARTICLE_TYPE_QUIZ,
          title: this.$t('article.article_type.quiz')
        },
        {
          id: ARTICLE_TYPE_RECIPE,
          title: this.$t('article.article_type.recipe')
        },
        {
          id: ARTICLE_TYPE_PHOTOSTORY,
          title: this.$t('article.article_type.photostory')
        },
        {
          id: ARTICLE_TYPE_TEST,
          title: this.$t('article.article_type.test')
        },
        {
          id: ARTICLE_TYPE_LONGFORM,
          title: this.$t('article.article_type.longform')
        },
        {
          id: ARTICLE_TYPE_DISEASE,
          title: this.$t('article.article_type.disease')
        },
        {
          id: ARTICLE_TYPE_ENCYCLOPEDY,
          title: this.$t('article.article_type.encyclopedy')
        },
        {
          id: ARTICLE_TYPE_ONLINE_REPORTAGE,
          title: this.$t('article.article_type.onlineReportage')
        }
      ]
    }
  }
}
