<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="reportageHasId"
    :click-handler-for-edit-btn="showArticleSelectModal"
  >
    <div v-if="!reportageHasId" class="d-flex justify-content-center">
      <div class="reportage-block__buttons">
        <button class="reportage-block__btn-empty" @click="showArticleSelectModal('short')">
          {{ $t('nmhEditor.blocks.reportageBlock.emptyShort') }}
        </button>
        <button class="reportage-block__btn-empty" @click="showArticleSelectModal('long')">
          {{ $t('nmhEditor.blocks.reportageBlock.emptyLong') }}
        </button>
      </div>
    </div>
    <div v-else class="reportage-block__not-empty-wrapper">
      <span class="reportage-block__icon">L</span>
      <span class="reportage-block__text">
        {{ $t('nmhEditor.blocks.reportageBlock.notEmpty') }} - {{ reportageData }}
      </span>
    </div>
    <ArticleSelectModal
      v-if="articleSelectModal"
      default-status="published"
      :default-type="defaultType"
      @select-article="setSelectedArticle"
      @close="closeArticleSelectModal"
    />
  </Block>
</template>

<script>
import coreApi from '@/api/core'
import Block from '@/components/nmhEditor/blocks/Block'
import ArticleSelectModal from '@/components/quiz/vlm/ArticleSelectModal'
import { ARTICLE_TYPE_ONLINE_REPORTAGE } from '@/components/mixins/valueObject/ArticleTypeMixin'

export default {
  name: 'ReportageBlock',
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  components: {
    Block,
    ArticleSelectModal
  },
  data () {
    return {
      selectedData: null,
      reportageArticleId: null,
      reportageArticleSettingStatus: null,
      reportageData: null,
      size: null,
      articleSelectModal: false,
      defaultType: ARTICLE_TYPE_ONLINE_REPORTAGE
    }
  },
  computed: {
    reportageHasId () {
      return this.properties.reportageArticleId !== ''
    }
  },
  methods: {
    showArticleSelectModal (size) {
      this.articleSelectModal = true
      this.size = size
    },
    closeArticleSelectModal () {
      this.articleSelectModal = false
    },
    loadReportageArticleData (id) {
      if (!isNaN(this.properties.reportageArticleId)) {
        return coreApi().get(`/article/${id}`)
          .then(response => {
            this.reportageData = response.data.field.title
            this.reportageArticleSettingStatus = response.data.setting.status
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    setSelectedArticle (article) {
      this.reportageArticleId = article.id
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'reportageArticleId',
        data: this.reportageArticleId
      })
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'reportageArticleSettingStatus',
        data: article.setting.status
      })
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'size',
        data: this.size
      })
      this.loadReportageArticleData(this.reportageArticleId)
    }
  },
  mounted () {
    if (this.reportageHasId) {
      this.loadReportageArticleData(this.properties.reportageArticleId)
    }
  }
}
</script>

<style scoped lang="scss">
.reportage-block {
  &__not-empty-wrapper {
    @include grid-gap(9px);
    display: grid;
    grid-template-columns: min-content auto;
  }
  &__buttons {
    display: flex;
    gap: 2rem;
  }
  &__btn-empty {
    @include font(500 13px "Roboto");
    @include margin(_ auto);
    @include padding(8px 14px 8px);
    @include radius(6px);
    cursor: pointer;
    display: block;
    background: #6599fe;
    color: #fff;
    border: none;
    transition: background 200ms;
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &__text {
    @include font(400 18px "Roboto");
    @include margin(0);
    @include relative(-1px _ _);
    color: #465674;
  }
  &__icon {
    @include font(400 12px "Roboto");
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    background: #6599fe;
    color: #fff;
    padding: .05rem;
  }
}
</style>
