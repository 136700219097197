<template>
  <div class="modal-third-party-embed">
    <div class="modal-third-party-embed__dialog-wr">
      <div class="modal-third-party-embed__header">
        <SvgIconPaste class="modal-third-party-embed__main-icon" />
        <h4 class="modal-third-party-embed__title">
          {{
            infographic ?
              $t('nmhEditor.modals.thirdPartyEmbed.titleInfographic') :
              $t('nmhEditor.modals.thirdPartyEmbed.titleSocialEmbed')
          }}
        </h4>
        <SvgIconTimes class="modal-third-party-embed__times" @click="modalHandlerToggle" />
      </div>
      <div class="modal-third-party-embed__content-wr">

        <div class="modal-third-party-embed__label">
          <span class="modal-third-party-embed__label-desc">
            <template>{{ $t('nmhEditor.modals.thirdPartyEmbed.inputLabel') }}:</template>
          </span>
          <small
            v-if="storyError"
            class="text-danger float-right"
          >
            <i class="fa fa-exclamation-triangle"></i>
            {{ $t('nmhEditor.modals.thirdPartyEmbed.storyError') }}
          </small>
          <div class="modal-third-party-embed__input-wrapper">
            <input
              class="modal-third-party-embed__input"
              v-model="inputValue"
              ref="modalThirdPartyEmbedInput"
              data-test="social-url-input"
            />
            <div
              :class="[
                'modal-third-party-embed__input-validator',
                { 'modal-third-party-embed__input-validator--valid': inputValue.length && inputValueIsValid },
                { 'modal-third-party-embed__input-validator--invalid': inputValue.length && !inputValueIsValid }
              ]"
            >
              <template v-if="inputValue.length && inputValueIsValid">&#10003;</template>
              <template v-if="inputValue.length && !inputValueIsValid">&times;</template>
            </div>
          </div>
        </div>
        <div class="modal-third-party-embed__panel">
          <div
            v-for="(type, index) in Object.keys(allowedTypes)"
            :key="`type-${index}`"
            :class="['modal-third-party-embed__panel-icon', { 'active': type === activeType }]"
            :data-test="type"
          >
            <component :is="getTypeIcon(type)" />
            <span class="modal-third-party-embed__panel-label">{{ $t(`author.${type}`) }}</span>
          </div>
        </div>
        <button
          class="modal-third-party-embed__btn-send"
          :disabled="!inputValueIsValid"
          @click="submitHandler"
          data-test="insert-into-article-button"
        >
          {{ $t('nmhEditor.modals.thirdPartyEmbed.btn.confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgIconTimes from '@/assets/img/svg/times.svg?inline'
import SvgIconPaste from '@/assets/img/svg/paste.svg?inline'
import SvgIconPinterest from '@/assets/img/svg/pinterest.svg?inline'
import SvgIconInstagram from '@/assets/img/svg/instagram.svg?inline'
import SvgIconTwitter from '@/assets/img/svg/twitter.svg?inline'
import SvgIconFacebook from '@/assets/img/svg/facebook.svg?inline'
import SvgIconPodcast from '@/assets/img/svg/podcast.svg?inline'
import SvgIconFlourish from '@/assets/img/svg/flourish.svg?inline'
import SvgIconTableau from '@/assets/img/svg/tableau.svg?inline'
import SvgIconInfogram from '@/assets/img/svg/infogram.svg?inline'
import { isURL } from '@/filters'
export default {
  name: 'ModalThirdPartyEmbed',
  components: {
    SvgIconPodcast,
    SvgIconTimes,
    SvgIconPaste,
    SvgIconPinterest,
    SvgIconInstagram,
    SvgIconTwitter,
    SvgIconFacebook,
    SvgIconFlourish,
    SvgIconTableau,
    SvgIconInfogram
  },
  props: {
    excludeTypes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      inputValue: '',
      inputValueIsValid: false,
      storyError: false,
      activeType: '',
      allowedTypesSocial: {
        pinterest: ['pinterest.com', 'pin.it'],
        instagram: ['instagram.com'],
        twitter: ['twitter.com', 'x.com'],
        facebook: ['facebook.com'],
        // podcast: ['https://developer.backtracks.fm/player/', 'https://player.backtracks.fm/backtracks/']
        podcast: ['https://www.podbean.com/']
      },
      allowedTypesInfographic: {
        flourish: ['flo.uri.sh/visualisation', 'public.flourish.studio/visualisation', 'public.flourish.studio/story'],
        tableau: ['public.tableau.com/views', 'tableauPlaceholder'],
        infogram: ['infogram.com', 'infogram-embed']
      }
    }
  },
  computed: {
    ...mapState({
      blocks: state => state.nmhEditorStore.data.blocks,
      selectBlockModalReplaceMode: state => state.nmhEditorStore.modals.selectBlock.replaceMode,
      currentArticle: state => state.article.detail,
      lastClickedBlock: state => state.nmhEditorStore.lastClickedBlock,
      infographic: state => state.nmhEditorStore.modals.thirdPartyEmbed.infographic
    }),
    allowedTypes () {
      console.log('excludeTypes', this.excludeTypes)
      const baseTypes = this.infographic
        ? this.allowedTypesInfographic
        : this.allowedTypesSocial

      return Object.keys(baseTypes)
        .filter(type => !this.excludeTypes.includes(type))
        .reduce((acc, key) => {
          acc[key] = baseTypes[key]
          return acc
        }, {})
    }
  },
  methods: {
    submitHandler () {
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.lastClickedBlock.index,
        property: 'type',
        data: this.activeType
      })
      if (this.inputValue.includes('x.com')) {
        this.inputValue = this.inputValue.replace('x.com', 'twitter.com')
      }
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.lastClickedBlock.index,
        property: 'originalContent',
        data: this.inputValue.trim()
      })
      this.$store.commit('nmhEditorStore/SET_MODAL_THIRD_PARTY_EMBED', {
        isVisible: false,
        infographic: false
      })
    },
    getTypeIcon (type) {
      const iconName = type.charAt(0).toUpperCase() + type.slice(1)
      return `SvgIcon${iconName}`
    },
    modalHandlerToggle () {
      this.$store.commit('nmhEditorStore/SET_MODAL_THIRD_PARTY_EMBED', {
        isVisible: false,
        infographic: false
      })
    },
    findValidType (inputUrl) {
      return Object.keys(this.allowedTypes).find(key => this.allowedTypes[key].some(url => inputUrl.includes(url)))
    },
    isSocialMediaStory (url) {
      return url.includes('/stories/')
    },
    inputIsValid (val) {
      this.storyError = this.isSocialMediaStory(val)
      const foundValidType = this.findValidType(val)
      if (foundValidType) {
        this.activeType = foundValidType
      }
      this.inputValueIsValid = isURL(val) && foundValidType && !this.storyError && this.inputValue.length
    }
  },
  watch: {
    inputValue (val) {
      this.inputIsValid(val)
    },
    activeType () {
      this.inputIsValid(this.inputValue)
    }
  },
  mounted () {
    document.body.style.overflowY = 'hidden'
    const blockData = this.blocks[this.lastClickedBlock.index]
    if (
      blockData.properties.type.length &&
      blockData.properties.originalContent.length
    ) {
      this.activeType = blockData.properties.type
      this.inputValue = blockData.properties.originalContent
    }
    this.$refs.modalThirdPartyEmbedInput.focus()
  },
  destroyed () {
    this.inputValue = ''
    document.body.style.overflowY = 'scroll'
  }
}
</script>

<style lang="scss" scoped>
.modal-third-party-embed {
  @include fixed(0, 100);
  @include size(100% 100vh);
  @include padding(_ 10px);
  overflow-y: scroll;
  background: rgba(0,0,0,.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &__dialog-wr {
    @include size(760px _);
    @include margin(40px _);
    @include radius(6px);
    overflow: hidden;
    background: #f2f3f7;
  }
  &__header {
    @include size(_ 60px);
    @include padding(_ 30px);
    @include grid-gap(8px);
    background: #fff;
    display: grid;
    align-items: center;
    grid-template-columns: min-content auto min-content;
  }
  &__times {
    cursor: pointer;
    transform: scale(1);
    transition: all 200ms;
    &:hover {
      transform: scale(1.2);
    }
  }
  &__title {
    @include font(500 18px "Roboto");
    @include margin(0);
    color: #465674;
    code {
      @include font(400 16px "Fira Code");
      @include padding(3px 8px);
      @include radius(4px);
      @include margin(_ _ _ 10px);
      background: #465674;
      color: #fff;
    }
  }
  &__main-icon {
    @include size(20px auto);
    fill: #6599fe;
  }
  &__content-wr {
    @include padding(30px);
  }
  &__btn-send {
    @include font(500 16px "Roboto");
    @include padding(10px 20px);
    @include radius(4px);
    @include margin(20px _ _ auto);
    transition: background 200ms;
    display: grid;
    color: #fff;
    background: #6599fe;
    border: none;
    cursor: pointer;
    &:hover:not(:disabled) {
      background: darken(#6599fe, 10%);
    }
    &:disabled {
      opacity: .7;
      cursor: not-allowed;
    }
  }
  &__label {
    width: 100%;
  }
  &__link-preview {
    margin-top: 1rem;
  }
  &__label-desc {
    font-family: "Roboto", sans-serif;
    font-size: rem(14px);
    font-weight: 400;
    color: #465674;
    opacity: .9;
    margin-bottom: .2rem;
    display: inline-block;
  }
  &__input-wrapper {
    position: relative;
  }
  &__input {
    @include size(100% 40px);
    @include radius(6px);
    @include padding(10px);
    overflow-y: scroll;
    display: block;
    border: 1px solid #d5d5d5;
    background: #fff;
    padding-right: 4rem;
  }
  &__input-validator {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 2.5rem;
    background: #f2f3f7;
    border: 1px solid #d5d5d5;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    &--valid {
      font-size: rem(20px);
      color: #01e02f;
    }
    &--invalid {
      font-size: rem(30px);
      color: #ff0000;
    }
  }
  &__panel {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }
  &__panel-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    fill: #6599fe;
    color: #6599fe;
    width: 4rem;
    height: 4rem;
    padding: 0.25rem 0.9rem;
    margin: 0 0.2rem;
    transform: scale(1);
    transition: all 200ms;
    font-size: 1.8rem;
    &.active {
      background: #d1dbe4;
      border-radius: 0.3rem;
    }
  }
  &__panel-label {
    font-size: 0.70rem;
    font-family: "Roboto", sans-serif;
    opacity: 0.9;
    color: #465674;
  }
  /deep/.ProseMirror {
    @include size(100%);
  }
}
</style>
https://public.flourish.studio/visualisation/11597006/
